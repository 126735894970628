<template>
  <div class="position-relative">
    <div
      v-if="isLoading"
      class=" d-flex justify-content-center align-items-start planning-spinner"
    >
      <Spinner />
    </div>
    <div class="pt-5 px-3">
      <div class="row d-flex justify-content-center labor-planning-content__container">
        <div class="col d-flex align-items-center flex-column p-0">
          <div class="card w-100">
            <div class="d-flex justify-content-between p-4">
              <h5 class="page-title">
                Planning
              </h5>
              <div
                v-if="form.brandId"
                class="d-flex justify-content-center align-items-center"
              >
                <img
                  v-if="hasBrandLogo"
                  class="pt-0"
                  :src="getBrandLogoPath"
                  alt="logo"
                  style="height: 40px;"
                />
                <h5
                  v-else
                  class="page-title px-5"
                >
                  {{ brandName }}
                </h5>
              </div>
              <div
                class="w-auto me-2 p-2"
                style="background: rgba(255, 245, 229, 1);"
              >
                <LaborPlanTooltip
                  :laborPlan="plan"
                >
                  Status: <span class="badge bg-warning">{{ planStatus }}</span>
                </LaborPlanTooltip>
              </div>
            </div>
            <div class="card-body w-100">
              <div class="d-flex align-items-center flex-column w-100">
                <div class="row w-100">
                  <SelectInput
                    v-model="form.startDate"
                    :options="weekSelectorOptions"
                    :disabled="!isNewPlan"
                    :errors="v$.form.startDate.$errors"
                    label="Date"
                    description="Date"
                    class="m-1 planning-configuration__select col"
                    @blur="v$.form.startDate.$touch"
                    @change="prepareTablesData"
                  />
                  <SelectInput
                    v-model="form.brandId"
                    :options="brandSelectorOptions"
                    :disabled="!isNewPlan"
                    :errors="v$.form.brandId.$errors"
                    label="Brand"
                    description="Brand"
                    class="m-1 planning-configuration__select col"
                    @change="handleBrandChange"
                    @blur="v$.form.brandId.$touch"
                  />
                  <SelectInput
                    v-model="form.location"
                    :options="locationOptions"
                    :disabled="!isNewPlan"
                    :errors="v$.form.location.$errors"
                    label="Location"
                    description="Location"
                    class="m-1 planning-configuration__select col"
                    @change="prepareTablesData"
                    @blur="v$.form.location.$touch"
                  />
                  <TextInput
                    v-model="form.osEmployeesPercent"
                    :readonly="!isEditAllowed"
                    :errors="v$.form.osEmployeesPercent.$errors"
                    label="OS Employee %"
                    description="OS Employee %"
                    class="m-1 planning-configuration__select col"
                    @blur="v$.form.osEmployeesPercent.$touch"
                  />
                  <TextInput
                    v-model="form.premiumDiscount"
                    :readonly="!isEditAllowed"
                    :errors="v$.form.premiumDiscount.$errors"
                    label="Premium or Discount %"
                    description="Premium or Discount %"
                    class="m-1 planning-configuration__text col"
                    @blur="v$.form.premiumDiscount.$touch"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="labor-planning-content__container"
            :class="{ 'd-none': !isReady }"
          >
            <LaborPlanningCommitmentBar
              :additionalHours="additionalNeededHours"
              :isEditAllowed="isEditAllowed"
              :isReviewAllowed="isReviewAllowed"
              :commitmentCost="commitmentCost"
              :additionalCost="additionalHoursCost"
              :commitmentHours="commitmentHours"
              :planStatus="plan.status"
              :planStartDate="plan.startDate"
              :isDirty="isFormChanged"
              @submitPlan="onPlanSubmit"
              @approvePlan="onPlanApprove"
              @rejectPlan="onPlanReject"
            />
            <LaborPlanningSection
              :columnDefs="forecastTableColumnDefs"
              :rowData="forecastTableRowData"
              :showTotals="false"
              :totalsFractionDigits="2"
              rowHeight="40"
            >
              <template #title>
                Forecast
              </template>
            </LaborPlanningSection>
            <LaborPlanningSection
              :editable="isEditAllowed"
              :columnDefs="uphCommitmentTableColumnDefs"
              :rowData="uphCommitmentTableRowData"
              rowHeight="40"
              @cellEdited="onUPHCellEditRequest"
            >
              <template #title>
                UPH Commitment
              </template>
              <template #btn>
                <button
                  class="btn btn-secondary commitment-bar__button-submit m-0 p-0 mt-1 mx-4 small"
                  @click="applyAvgUPH"
                >
                  Apply Avg UPH to All
                </button>
              </template>
            </LaborPlanningSection>
            <LaborPlanningSection
              ref="additional-labor-hours-table"
              :editable="isEditAllowed"
              :columnDefs="additionalLaborHoursTableColumnDefs"
              :rowData="additionalLaborHoursTableRowData"
              :revenueTypesList="additionalActivitiesList"
              rowHeight="40"
              @cellEdited="onAHCellEditRequest"
            >
              <template #title>
                Additional Needed Hours
                <span class="small">
                  ({{ additionalNeededHours }} hours)
                </span>
              </template>
            </LaborPlanningSection>
            <LaborPlanningSection
              ref="labor-hours-commitment-table"
              :columnDefs="laborHoursCommitmentTableColumnDefs"
              :rowData="laborHoursCommitmentTableRowData"
              :showTotals="true"
              :totalsFractionDigits="1"
            >
              <template #title>
                Labor Hours Commitment
                <span class="small">
                  ({{ commitmentHours }} hours{{ additionalNeededHours > 0 ? ' including additional hours' : '' }})
                </span>
              </template>
            </LaborPlanningSection>
            <LaborPlanningSection
              ref="labor-cost-commitment-table"
              :columnDefs="laborCostCommitmentTableColumnDefs"
              :rowData="laborCostCommitmentTableRowData"
              :showTotals="true"
              rowHeight="40"
            >
              <template #title>
                Cost of Labor Commitment
                <span class="small">
                  ({{ formattedCommitmentCosts }}{{ additionalNeededHours > 0 ? ' including additional cost' : '' }})
                </span>
              </template>
            </LaborPlanningSection>
            <LaborPlanningSection
              :columnDefs="employeeCommitmentTableColumnDefs"
              :rowData="employeeCommitmentTableRowData"
              rowHeight="55"
            >
              <template #title>
                Employee Commitment
                <span class="small">
                  ({{ averageEmployees }} employees, considering {{ laborPlanningRule.hoursPerDay
                  }} Hrs / employee{{ additionalNeededHours > 0 ? ', including additional hours)' : ')' }}
                </span>
              </template>
            </LaborPlanningSection>
          </div>
          <Spinner
            v-if="showSpinner"
          />
        </div>
      </div>
    </div>
    <ConfirmationModal
      ref="reject-plan-confirmation-modal"
      :onCancelHandler="onCancelHandler"
      :onConfirmHandler="onRejectConfirmHandler"
      :preventHide="true"
    >
      <template #content>
        <div
          class="alert alert-danger"
          role="alert"
        >
          <slot name="alert-message">
            <h6>
              <i class="bi bi-exclamation-triangle" />
              Please add an explanation of the reasons
              why the plan was rejected
            </h6>
            <TextArea
              v-model="rejectReason"
              label="Put the rejection reason here (up to 500 symbols)"
              :errors="v$.rejectReason.$errors"
              @blur="v$.rejectReason.$touch"
            />
          </slot>
        </div>
      </template>
      <template #confirm-button-text>
        <slot name="confirm-button">
          Reject Plan
        </slot>
      </template>
    </ConfirmationModal>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';

import {
  planStatuses,
  revenueTypesList,
  additionalActivitiesList, dayBeforeNewWeekSelect,
} from '@/components/LaborPlanning/constants';
import TextInput from '@/components/common/TextInput';
import SelectInput from '@/components/common/SelectInput';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import LaborPlanningSection from '@/components/LaborPlanning/LaborPlanningSection';
import AgGreedAveragesColumnCellRenderer from './AgGreedAveragesColumnCellRenderer';
import {
  getISODateString,
  getWeekDates,
  getWeekStart,
  millisecondsInDay,
  parseISOLocal,
  toUsDateFormat
} from '@/lib/date';
import LaborPlanningCommitmentBar from './LaborPlanningCommitmentBar';
import Spinner from '@/components/common/Spinner';
import {
  buildCommitment,
  buildWeekPlan,
  calculateCombination,
  getCellEvaluator, planEditPeriodExpired, planReviewPeriodExpired,
  uphCommitmentCellValidator, toUSMoneyFormat
} from './utils';
import { decimal, maxLength, maxValue, minValue, required } from '@vuelidate/validators';
import TextArea from '@/components/common/TextArea';
import LaborPlanTooltip from './LaborPlanTooltip';
import { editableCellRenderer } from '@/lib/agGridCellRenderers';
import config from '@/config';
import { userPrivileges } from '@/components/constants';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    TextArea,
    Spinner,
    LaborPlanningCommitmentBar,
    LaborPlanningSection,
    SelectInput,
    TextInput,
    ConfirmationModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedAveragesColumnCellRenderer,
    LaborPlanTooltip
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      rejectPlanConfirmationModal: null,
      rejectReason: null,
      plan: {},
      uphCommitment: [],
      originalUphCommitment: [],
      additionalHoursCommitment: [],
      originalAdditionalHoursCommitment: [],
      laborCostCommitment: [],
      laborHoursCommitment: [],
      employeeCommitment: [],
      brandUphAverages: {},
      brandAHAverages: {},
      brandForecastAverages: {},
      brandForecastActuals: {},
      previousWeeks: [],
      form: {
        startDate: null,
        brandId: null,
        location: null,
        osEmployeesPercent: null,
        premiumDiscount: 0,
      },
      isBrandDataInitialised: false,
      isPlanDataInitialised: false,
      hasBrandLogo: false,
      isLoading: false
    };
  },
  validations() {
    return {
      rejectReason: {
        required,
        maxLength: maxLength(500)
      },
      form: {
        brandId: {
          required
        },
        startDate: {
          required
        },
        osEmployeesPercent: {
          required,
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(100)
        },
        premiumDiscount: {
          required,
          decimal
        },
        location: {
          required
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      availableBrands: 'laborPlanning/availableBrands',
      laborPlanningRule: 'laborPlanning/laborPlanningRule',
      brandForecasts: 'forecast/forecastForLaborPlanning',
      locations: 'brandManagement/brandLocations'
    }),
    additionalActivitiesList() {
      return additionalActivitiesList;
    },
    isReady() {
      return this.isBrandDataInitialised && this.isPlanDataInitialised;
    },
    showSpinner() {
      if (!this.planId) {
        return this.form.brandId &&
          !this.isBrandDataInitialised &&
          this.form.location;
      }

      return !this.isBrandDataInitialised || !this.isPlanDataInitialised;
    },
    isFormChanged() {
      const toCompare = _.keys(this.form);
      let isEverythingMatch = true;
      _.forEach(toCompare, (key) => {
        if (this.form[key] != this.plan[key]) {
          isEverythingMatch = false;
        }
      });

      if (!isEverythingMatch) {
        return true; // the form data was changed, no additional checks required;
      }

      _.forEach(this.originalUphCommitment, (value, index) => {
        _.forEach(this.upcomingWeekDays, (weekDay) => {
          if (value[weekDay] !== this.uphCommitment[index][weekDay]) {
            isEverythingMatch = false;
          }
        });
      });

      _.forEach(this.originalAdditionalHoursCommitment, (value, index) => {
        _.forEach(this.upcomingWeekDays, (weekDay) => {
          if (value[weekDay] !== this.additionalHoursCommitment[index][weekDay]) {
            isEverythingMatch = false;
          }
        });
      });

      return !isEverythingMatch;
    },
    isNewPlan() {
      return this.planStatus === planStatuses.NOT_SUBMITTED;
    },
    isEditAllowed() {
      const hasEditableStatus = [
        planStatuses.NOT_SUBMITTED,
        planStatuses.REJECTED,
        planStatuses.RESUBMISSION_ALLOWED,
        planStatuses.SUBMITTED
      ].includes(this.planStatus);

      return hasEditableStatus &&
        !planEditPeriodExpired(this.plan) &&
        hasPrivilege(userPrivileges.updateLpPlan);
    },
    isReviewAllowed() {
      const hasReviewableStatus = [
        planStatuses.SUBMITTED,
        planStatuses.RESUBMITTED
      ].includes(this.planStatus);

      return hasPrivilege(userPrivileges.getLpPlan) && hasReviewableStatus && !planReviewPeriodExpired(this.plan);
    },
    laborRate() {
      const { osPerHourCost, agenciesPerHourCost } = this.laborPlanningRule;
      const coefficient = parseFloat(this.form.osEmployeesPercent) / 100;
      return coefficient * osPerHourCost + (1 - coefficient) * agenciesPerHourCost;
    },
    brandSelectorOptions() {
      if (this.planId) {
        return [{ key: this.plan.brandId, value: this.plan.brandName }];
      }

      return _.map(this.availableBrands, (brand) => {
        return { key: brand.id, value: brand.name };
      });
    },
    locationOptions() {
      if (this.planId) {
        return [{ key: this.plan.location, value: this.plan.location }];
      }

      return _.map(this.locations, (location) => {
        return { key: location, value: location };
      });
    },
    averagesColumnsDef() {
      return _.map(this.previousWeeks, (weekStartDay) => {
        const field = toUsDateFormat(new Date(weekStartDay), undefined);
        return {
          headerName: `W/S ${field}`,
          field,
          cellRenderer: 'AgGreedAveragesColumnCellRenderer'
        };
      });
    },
    weekSelectorOptions() {
      const currentDate = new Date();
      const numWeekToAdd = currentDate.getDay() < dayBeforeNewWeekSelect ? 1 : 2;
      if (this.planId && ! this?.form?.startDate) {
        return [];
      }

      const nextAvailableWeeks = this.planId ? [
        parseISOLocal(this.form.startDate)
      ] : [
        new Date(currentDate.getTime() + numWeekToAdd * 7 * millisecondsInDay),
        new Date(currentDate.getTime() + (numWeekToAdd + 1) * 7 * millisecondsInDay)
      ];

      return _.map(nextAvailableWeeks, (weekDay) => {
        const weekStartDate = getWeekStart(weekDay);
        const dtString = toUsDateFormat(weekStartDate, undefined);
        return { key: getISODateString(weekStartDate), value: `W/S ${dtString}` };
      });
    },
    premiumDiscountParsed() {
      const parsed = parseFloat(this.form.premiumDiscount);

      if (isNaN(parsed)) {
        return 0.;
      }
      return parsed;
    },
    upcomingWeekAdditionalHeader() {
      if (this.premiumDiscountParsed === 0.) {
        return '';
      }
      return ` with ${Math.abs(this.premiumDiscountParsed)}% ${ this.premiumDiscountParsed > 0 ? 'Premium' : 'Discount' }`;
    },
    upcomingWeekDays() {
      if (!this.form.startDate) {
        return [];
      }
      return _.map(getWeekDates(parseISOLocal(this.form.startDate)), (date) => toUsDateFormat(date, undefined));
    },
    forecastTableColumnDefs() {
      const header = `Upcoming Week Forecast ${this.upcomingWeekAdditionalHeader}`;
      const cols = this.buildColumnDefs(header);
      cols[0].cellRenderer = 'AgGreedAveragesColumnCellRenderer';
      cols[0].cellRendererParams = {
        textAlign: 'left'
      };

      return cols;
    },
    uphCommitmentTableColumnDefs() {
      const header = `Upcoming Week UPH ${this.upcomingWeekAdditionalHeader}`;
      const options = {
        editable: this.isEditAllowed,
        cellRenderer: editableCellRenderer,
      };
      return this.buildColumnDefs(header, options);
    },
    additionalLaborHoursTableColumnDefs() {
      const header = `Upcoming Week Additional Labour Hours ${this.upcomingWeekAdditionalHeader}`;
      const options = {
        editable: this.isEditAllowed,
        cellRenderer: editableCellRenderer,
      };
      return this.buildColumnDefs(header, options);
    },
    laborCostCommitmentTableColumnDefs() {
      const header = `Upcoming Week Labor Cost ${this.upcomingWeekAdditionalHeader}`;
      const options = {
        valueFormatter:  (params) => {
          return toUSMoneyFormat(params.value);
        }
      };
      return this.buildColumnDefs(header, options);
    },
    laborHoursCommitmentTableColumnDefs() {
      const header = `Upcoming Week Labor Hours ${this.upcomingWeekAdditionalHeader}`;
      return this.buildColumnDefs(header);
    },
    employeeCommitmentTableColumnDefs() {
      const header = `Upcoming Week Labor Employees ${this.upcomingWeekAdditionalHeader}`;
      const columnDefs = this.buildColumnDefs(header);
      columnDefs[0].headerName = '';
      columnDefs[0].cellRenderer = (params) => `<p style="line-height: 1.5;">${params.value}</p>`;
      return columnDefs;
    },
    forecastTableBaseData() {
      if (!this.isReady) {
        return [];
      }

      return _.map(revenueTypesList, (revenueType) => {
        const forecastRow = { revenueType };

        _.forEach(this.averagesColumnsDef, ({ field }) => {
          forecastRow[field] = this.brandForecastAverages[field][forecastRow.revenueType];
        });

        const revenueTypeRow = this.brandForecasts[revenueType];
        _.forEach(this.upcomingWeekDays, (day) => {
          if (!revenueTypeRow || !revenueTypeRow[day]) {
            forecastRow[day] = 0;
            return;
          }
          forecastRow[day] = _.round(revenueTypeRow[day] * (1 + this.premiumDiscountParsed / 100. ));
        });
        return forecastRow;
      });
    },
    forecastTableRowData() {
      return _.map(this.forecastTableBaseData, (rowData) => {
        const row = { ...rowData };
        _.forEach(this.averagesColumnsDef, ({ field }) => {
          row[field] = {
            forecastAvg: row[field],
            actualAvg: this.brandForecastActuals[field][row.revenueType]
          };
        });

        const { revenueType } = row;

        row.revenueType = {
          forecastAvg: revenueType,
          actualAvg: 'Actual'
        };

        return row;
      });
    },
    uphCommitmentTableRowData() {
      if (!this.isReady) {
        return [];
      }

      return _.map(this.uphCommitment, (row) => {
        const result = { ...row };
        _.forEach(this.averagesColumnsDef, ({ field }) => {
          result[field] = this.brandUphAverages[field][result.revenueType];
        });
        return result;
      });
    },
    additionalLaborHoursTableRowData() {
      if (!this.isReady) {
        return [];
      }

      return _.map(this.additionalHoursCommitment, (row) => {
        const result = { ...row };
        _.forEach(this.averagesColumnsDef, ({ field }) => {
          result[field] = this.brandAHAverages[field][result.revenueType];
        });
        return result;
      });
    },
    laborCostCommitmentTableRowData() {
      if (!this.isReady) {
        return [];
      }

      const computed = calculateCombination(
        this.forecastTableBaseData,
        this.uphCommitmentTableRowData,
        getCellEvaluator(0, this.laborRate)
      );

      if (this.planId  && !this.isEditAllowed) {
        return this.mixWithSavedData(
          computed,
          this.laborCostCommitment,
          (n) =>_.round(n)
        );
      }

      return computed;
    },
    laborHoursCommitmentTableRowData() {
      if (!this.isReady) {
        return [];
      }
      const computed = calculateCombination(
        this.forecastTableBaseData,
        this.uphCommitmentTableRowData,
        getCellEvaluator(1, 1)
      );

      if (this.planId  && !this.isEditAllowed) {
        return this.mixWithSavedData(
          computed,
          this.laborHoursCommitment,
          (n) => typeof n === 'number' && n.toFixed(1)
        );
      }

      return computed;
    },
    employeeCommitmentTableRowData() {
      if (!this.isReady) {
        return [];
      }
      const result = { ...this.$refs['labor-hours-commitment-table'].totals };
      const keysForCalculation = _.filter(_.keys(result), (k) => k !== 'revenueType');
      _.forEach(keysForCalculation, (key) => {
        result[key] = (result[key]/this.laborPlanningRule.hoursPerDay).toFixed(1);
      });
      result.revenueType = 'FTE <br /> (Unique Employees)';
      result.isBoldRow = true;

      if (this.planId  && !this.isEditAllowed) {
        const formattedValues = _.reduce(
          this.employeeCommitment, (acc, value, key) => {
            acc[key] = (value).toFixed(1);
            return acc;
          }, {});
        return [{ ...result, ...formattedValues }];
      }
      return [result];
    },
    planId() {
      const routeParam = this.$router.currentRoute.value?.params?.planId;
      return routeParam === 'new' ? null : routeParam;
    },
    planStatus() {
      return this.planId ? this.plan.status : planStatuses.NOT_SUBMITTED;
    },
    commitmentCost() {
      if (!this.isReady) {
        return 0;
      }
      if (this.planId && !this.isEditAllowed) {
        return this.plan.commitmentCost;
      }
      return this.calculateTotalCommitment(this.$refs['labor-cost-commitment-table'].totals, this.laborRate);
    },
    formattedCommitmentCosts() {
      return toUSMoneyFormat(this.commitmentCost);
    },
    commitmentHours() {
      if (!this.isReady) {
        return 0;
      }
      if (this.planId  && !this.isEditAllowed) {
        return this.plan.commitmentHours;
      }
      return this.calculateTotalCommitment(this.$refs['labor-hours-commitment-table'].totals, 1);
    },
    additionalNeededHours() {
      if (!this.isReady) {
        return 0;
      }
      if (this.planId && !this.isEditAllowed) {
        return this.plan.indirectHours;
      }

      const totals = this.$refs['additional-labor-hours-table'].totals;

      return _.reduce(this.upcomingWeekDays, (acc, value) => {
        acc += parseFloat(totals[value]);
        return acc;
      }, 0);
    },
    additionalHoursCost() {
      return _.round(this.additionalNeededHours * this.laborRate, 1);
    },
    averageEmployees() {
      if (!this.isReady) {
        return 0;
      }
      const totalHours = parseFloat(this.commitmentHours);
      const daysWithEmployeeHours = this.countDaysWithEmployeeHours();

      return daysWithEmployeeHours > 0 ? Number((totalHours / daysWithEmployeeHours / this.laborPlanningRule.hoursPerDay).toFixed(1)) : 0;
    },
    brandName() {
      return this.availableBrands[this.form.brandId].name;
    },
    getBrandLogoPath() {
      return `${config.brandLogoCDN}` + this.availableBrands[this.form.brandId].lookupName + '.png';
    }
  },
  async mounted() {
    this.rejectPlanConfirmationModal = this.$refs['reject-plan-confirmation-modal'].confirmationModal;
    await Promise.all([
      this.fetchAvailableBrands(),
      this.fetchLaborPlanningRule()
    ]);

    await this.initialise();
    await this.checkBrandLogo();
  },
  methods: {
    ...mapActions({
      fetchLaborPlanById: 'laborPlanning/fetchLaborPlanById',
      changePlanStatus: 'laborPlanning/changePlanStatus',
      fetchAvailableBrands: 'laborPlanning/fetchAvailableBrands',
      fetchLaborPlanningRule: 'laborPlanning/fetchRule',
      submitLaborPlan: 'laborPlanning/submitLaborPlan',
      updateLaborPlan: 'laborPlanning/updateLaborPlan',
      fetchBrandForecastDetails: 'forecast/fetchBrandForecastDetails',
      fetchUphAverages: 'laborPlanning/fetchBrandUphAverages',
      fetchBrandForecastAverages: 'forecast/fetchBrandForecastAverages',
      fetchBrandForecastActuals: 'forecast/fetchBrandForecastActuals',
      fetchPreviousWeekAverages: 'laborPlanning/fetchPreviousWeekAverages',
      fetchBrandLocations: 'brandManagement/fetchBrandLocations',
      fetchLaborPlans: 'laborPlanning/fetchLaborPlans'
    }),
    calculateTotalCommitment(totals, multiplayer) {
      const totalSum = _.reduce(this.upcomingWeekDays, (acc, value) => {
        acc += parseFloat(totals[value]);
        return acc;
      }, 0);
      const additionalHours = parseFloat(this.additionalNeededHours);
      return _.round(totalSum + (isNaN(additionalHours) ? 0 : additionalHours * multiplayer), 1);
    },
    async initialise() {
      this.isBrandDataInitialised = false;
      this.isPlanDataInitialised = false;
      if (this.planId) {
        this.plan = await this.fetchLaborPlanById(this.planId);
        this.form.brandId = this.plan.brandId;
        this.form.location = this.plan.location;
        this.form.osEmployeesPercent = this.plan.osEmployeesPercent;
        this.form.startDate = this.plan.startDate;
        this.form.premiumDiscount = this.plan.premiumDiscount;
        this.uphCommitment = this.buildCommitment(this.plan.weekPlan);
        this.originalUphCommitment = this.buildCommitment(this.plan.weekPlan);
        this.additionalHoursCommitment = this.buildCommitment(
          this.plan.additionalHoursCommitment,
          additionalActivitiesList
        );
        this.originalAdditionalHoursCommitment = this.buildCommitment(
          this.plan.additionalHoursCommitment,
          additionalActivitiesList
        );
        this.laborCostCommitment = this.buildCommitment(this.plan.laborCostCommitment);
        this.laborHoursCommitment = this.buildCommitment(this.plan.laborHoursCommitment);
        this.employeeCommitment = this.plan.employeeCommitment;
      } else {
        this.form.startDate = this.weekSelectorOptions[0].key;
        this.form.premiumDiscount = this.laborPlanningRule.defaultPremiumDiscount;
        this.form.osEmployeesPercent = 100;
      }
      this.isPlanDataInitialised = true;
      this.previousWeeks = this.getPreviousWeeks(this.plan?.createdAt);
      await this.prepareTablesData();
    },
    buildDefaultUphCommitmentData() {
      return _.map(revenueTypesList, (revenueType) => {
        const row = { revenueType };
        _.forEach(this.averagesColumnsDef, ({ field }) => {
          row[field] = this.brandUphAverages[field][revenueType];
        });

        _.forEach(this.upcomingWeekDays, (day) => {
          return row[day] = 0;
        });
        return row;
      });
    },
    buildDefaultAhCommitmentData() {
      return _.map(additionalActivitiesList, (revenueType) => {
        const row = { revenueType };
        _.forEach(this.averagesColumnsDef, ({ field }) => {
          row[field] = this.brandAHAverages[field][revenueType];
        });

        _.forEach(this.upcomingWeekDays, (day) => {
          return row[day] = 0;
        });
        return row;
      });
    },
    onUPHCellEditRequest(e) {
      const colId = e.column.colId;
      const rowIndex = e.rowIndex;
      if (!revenueTypesList.includes(e.data.revenueType)) {
        return;
      }
      const parsedValue = parseInt(e.newValue);
      const errors = uphCommitmentCellValidator(parsedValue);
      if (errors.length > 0) {
        const message = errors.join(' ');
        this.$toast.warning(message, { duration: 6000 });
        return;
      }

      this.uphCommitment[rowIndex][colId] = parsedValue;
      this.uphCommitment = [...this.uphCommitment];
    },
    onAHCellEditRequest(e) {
      const colId = e.column.colId;
      const rowIndex = e.rowIndex;
      if (!additionalActivitiesList.includes(e.data.revenueType)) {
        return;
      }
      const parsedValue = parseInt(e.newValue);
      const errors = uphCommitmentCellValidator(parsedValue);
      if (errors.length > 0) {
        const message = errors.join(' ');
        this.$toast.warning(message, { duration: 6000 });
        return;
      }

      this.additionalHoursCommitment[rowIndex][colId] = parsedValue;
      this.additionalHoursCommitment = [...this.additionalHoursCommitment];
    },
    buildColumnDefs(header, regularCellOptions = {}, withAverages = true) {
      const defaultWidth = 120;
      const cellStyleAvgCells = { textAlign: 'right' };
      const cellStyleRegularColumns = {
        textAlign: 'center',
        ..._.get(regularCellOptions, 'cellStyle', {})
      };

      const columnDefs = [{
        headerName: 'Revenue Type',
        field: 'revenueType',
        width: 230,
        suppressMovable: true,
        resizable: false,
        cellStyle: {
          textAlign: 'left'
        }
      }];

      if (withAverages) {
        const averagesColumns = _.map(this.averagesColumnsDef, (columnDef) => {
          return {
            ...columnDef,
            width: 150,
            cellStyle: cellStyleAvgCells,
            suppressMovable: true,
            resizable: false
          };
        });

        columnDefs.push({
          headerName: 'Previous Week 5 Day Averages',
          children: averagesColumns,
          suppressMovable: true,
          resizable: false,
        });
      }


      const regularColumns = _.map(this.upcomingWeekDays, (day) => {
        return {
          headerName: day,
          field: day,
          width: defaultWidth,
          ...regularCellOptions,
          cellStyle: cellStyleRegularColumns,
          suppressMovable: true,
          resizable: false
        };
      });
      columnDefs.push({
        headerName: header,
        children: regularColumns,
        suppressMovable: true,
        resizable: false
      });

      return columnDefs;
    },
    async onPlanSubmit() {
      this.isLoading = true;

      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) {
        this.$toast.error('The plan must be correct. Check input fields.');
        return;
      }

      const employeeCommitment = _.pick(this.employeeCommitmentTableRowData[0], this.upcomingWeekDays);

      const dataToSubmit = {
        brandId: this.form.brandId,
        brandName: this.availableBrands[this.form.brandId].name,
        location: this.form.location,
        osEmployeesPercent: this.form.osEmployeesPercent,
        agenciesCostPerHour: this.laborPlanningRule.agenciesPerHourCost,
        osCostPerHour: this.laborPlanningRule.osPerHourCost,
        hoursPerDay: this.laborPlanningRule.hoursPerDay,
        commitmentCost: this.commitmentCost,
        commitmentHours: this.commitmentHours,
        startDate: this.form.startDate,
        indirectHours: this.additionalNeededHours,
        premiumDiscount: this.form.premiumDiscount,
        weekPlan: buildWeekPlan(this.uphCommitment, this.upcomingWeekDays),
        additionalHoursCommitment: buildWeekPlan(this.additionalHoursCommitment, this.upcomingWeekDays),
        laborHoursCommitment: buildWeekPlan(this.laborHoursCommitmentTableRowData, this.upcomingWeekDays),
        laborCostCommitment: buildWeekPlan(this.laborCostCommitmentTableRowData, this.upcomingWeekDays),
        employeeCommitment
      };

      try {
        let planId;
        if (!this.planId) {
          planId = await this.submitLaborPlan(dataToSubmit);
        } else {
          planId = await this.updateLaborPlan({
            planId: this.planId,
            patch: dataToSubmit
          });
        }
        await this.fetchLaborPlans();

        this.$toast.success('The plan submitted successfully.');
        await this.$router.push({ name: 'labor-planning-view', params: { planId }});
        await this.initialise();
      } catch (e) {
        this.$toast.error('Failed to save the plan.');
      } finally {
        this.isLoading = false;
      }
    },
    async handleBrandChange() {
      await this.fetchBrandLocations(this.form.brandId);
      await this.checkBrandLogo();
      this.form.location = null;
      await this.prepareTablesData();
    },
    async prepareTablesData() {
      this.isBrandDataInitialised = false;
      if (!this.form.brandId || !this.form.location) {
        return;
      }
      const dateStart = parseISOLocal(this.weekSelectorOptions[0].key);
      const dateEnd = new Date(dateStart.getTime() + 13 * millisecondsInDay);
      const requestParams = {
        branId: this.form.brandId,
        dateStart: getISODateString(dateStart),
        dateEnd: getISODateString(dateEnd),
        location: this.form.location
      };
      try {
        await this.fetchBrandForecastDetails(requestParams);
        const { avgActualUnits, avgForecastUnits, avgUph } = await this.buildHistoricalData(this.form.brandId, this.form.location);
        this.brandUphAverages = avgUph;
        this.brandForecastAverages = avgForecastUnits;
        this.brandForecastActuals = avgActualUnits;
        this.brandAHAverages = this.buildAdditionalHoursAverageStub();
        if (!this.planId) {
          this.uphCommitment = this.buildDefaultUphCommitmentData();
          this.additionalHoursCommitment = this.buildDefaultAhCommitmentData();
        }
        this.isBrandDataInitialised = true;
      } catch (e) {
        this.brandUphAverages = {};
        this.brandForecastAverages = {};
        this.brandForecastActuals = {};

        this.$toast.error('Error during fetch brand data. Please try again later or contact with the administrator.');
      }
    },
    async buildHistoricalData(brandId, location) {
      const historicalData = [];
      for await (const week of this.previousWeeks) {
        const weekDateISOStr = getISODateString(week);
        const averages = await this.fetchPreviousWeekAverages({
          brandId: brandId,
          weekStartDate: weekDateISOStr,
          location: location
        });
        historicalData.push(averages);
      }

      return _.reduce(historicalData, (acc, value) => {
        _.forEach(_.keys(value), (averageType) => {
          acc[averageType] = _.merge(acc[averageType], value[averageType]);
        });
        return acc;
      }, {});
    },
    buildAdditionalHoursAverageStub() {
      const data = [];

      _.forEach(this.averagesColumnsDef, ({ field }) => {
        data[field] = {};
        _.forEach(additionalActivitiesList, (revenueType) => {
          data[field][revenueType] = 'N/A';
        });
      });

      return data;
    },
    getPreviousWeeks(weekDate) {
      const startDate = weekDate ? new Date(weekDate) : new Date();
      const startTime = startDate.getTime();

      return [
        getWeekStart((new Date(startTime - 14 * millisecondsInDay))),
        getWeekStart((new Date(startTime - 7 * millisecondsInDay)))
      ];
    },
    mixWithSavedData(computedData, savedData, formatter) {
      const computedDataMap = _.keyBy(computedData, 'revenueType');
      const savedDataMap = _.keyBy(savedData, 'revenueType');
      _.forEach(this.upcomingWeekDays, (date) => {
        _.forEach(revenueTypesList, (revenueType) => {
          const value = formatter ?
            formatter(savedDataMap[revenueType][date]) :
            savedDataMap[revenueType][date];

          computedDataMap[revenueType][date] = value;
        });
      });

      return _.values(computedDataMap);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onPlanApprove() {
      this.isLoading = true;
      try {
        await this.changePlanStatus({
          id: this.planId,
          status: planStatuses.APPROVED
        });
        this.$toast.success('Plan updated successfully.');
        await this.$router.push({ name: 'labor-planning' });
      } catch (e) {
        this.$toast.error(
          'Something went wrong. Please check your permissions or contact with the administrator'
        );
      } finally {
        this.isLoading = false;
      }
    },
    onPlanReject() {
      this.rejectPlanConfirmationModal.show();
    },
    onCancelHandler() {
      this.rejectPlanConfirmationModal.hide();
    },
    async onRejectConfirmHandler() {
      const isReasonValid = await this.v$.rejectReason.$validate();
      if (!isReasonValid) {
        const messages = _.map(this.v$.rejectReason.$errors, '$message');
        this.$toast.warning(messages.join(''));
        return;
      }
      try {
        await this.changePlanStatus({
          id: this.planId,
          status: planStatuses.REJECTED,
          rejectReason: this.rejectReason
        });
        await this.fetchLaborPlans();

        this.$toast.success('Plan updated successfully.');
        await this.$router.push({ name: 'labor-planning' });
      } catch (e) {
        this.$toast.error(
          'Something went wrong. Please check your permissions or contact with the administrator'
        );
      }
      this.rejectPlanConfirmationModal.hide();
    },
    getAverage(array) {
      if (array.length < 1) {
        return 0;
      }

      return array.reduce((sum, x) => sum + x, 0) / array.length;
    },
    buildAveragedUphCommitmentData() {
      return _.map(revenueTypesList, (revenueType) => {
        const row = { revenueType };
        let averages = [];
        _.forEach(this.averagesColumnsDef, ({ field }) => {
          row[field] = this.brandUphAverages[field][revenueType];
          averages.push(this.brandUphAverages[field][revenueType]);
        });

        const weightedAvg = _.round(this.getAverage(averages));
        _.forEach(this.upcomingWeekDays, (day) => {
          return row[day] = weightedAvg;
        });

        return row;
      });
    },
    applyAvgUPH(e) {
      e.preventDefault();

      const commitment = this.buildAveragedUphCommitmentData();
      this.uphCommitment = [...commitment];

      return false;
    },
    buildCommitment(weekPlan, types) {
      return buildCommitment(weekPlan, this.upcomingWeekDays, types || revenueTypesList);
    },
    async checkBrandLogo() {
      const imagePath = `${config.brandLogoCDN}` + this.availableBrands[this.form.brandId]?.lookupName + '.png';
      const img = new Image();
      img.onload = () => {
        this.hasBrandLogo = true;
      };
      img.onerror = () => {
        this.hasBrandLogo = false;
      };
      img.src = imagePath;
    },
    countAndTrackNonZeroDays(arr, nonZeroDaysSet) {
      let count = 0;
      _.forEach(arr, (obj) => {
        _.forEach(obj, (value, key) => {
          if (key !== 'revenueType' && value !== 0) {
            if (!nonZeroDaysSet.has(key)) {
              nonZeroDaysSet.add(key);
              count++;
            }
          }
        });
      });
      return count;
    },
    countDaysWithEmployeeHours() {
      let nonZeroDaysSet = new Set();
      const laborHoursEmployeeDays = this.countAndTrackNonZeroDays(this.laborHoursCommitment, nonZeroDaysSet);
      const additionalHoursEmployeeDays = this.countAndTrackNonZeroDays(this.additionalHoursCommitment, nonZeroDaysSet);

      return laborHoursEmployeeDays + additionalHoursEmployeeDays;
    },
  },
};
</script>
<style>
.labor-planning-content__container {
  margin: 0 auto;
  width: 1450px;
}

.page-title {
  font-family: HW-Cigars-Regular, serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
}

.planning-configuration__select {
  width: 200px;
}

.planning-configuration__text {
  width: 200px;
}

.ag-theme-alpine .ag-header-group-cell-label {
  justify-content: center;
}

.mr-3.5 {
  margin-right: 1.25rem !important;
}

.planning-spinner {
  padding-top: 300px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 101;
}
</style>
